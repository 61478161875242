import './App.css';
import { Link } from "react-router-dom";

function RedirectPage() {
  window.location.replace("https://apps.apple.com/app/apple-store/id6445870637");

  return (
    <>
    </>
  );
}

export default RedirectPage;